@import "assets/scss/variables";
@import "assets/scss/fonts";
@import "assets/scss/utils";

.wrapper {
  position: relative;
  h5 {
    margin: 0 0 4px;
  }
  p {
    margin: 0 0 32px;
  }

  button {
    position: absolute;
    top: 0;
    left: -12px;
  }
}
