@import "assets/scss/variables";
@import "assets/scss/fonts";
@import "assets/scss/utils";

.wallet-container {
  position: relative;
}

.wallet-info-wrapper {
  position: absolute;
  top: 30px;
  right: 0;
  padding-top: 30px;
  width: 450px;

  &-mobile {
    width: 100%;
    max-height: 0px;
    overflow-y: hidden;
    transition: max-height 300ms;
  }
}

.wallet-info {
  background: var(--menu-bg);
  color: var(--menu-color);
  border-radius: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  box-shadow: var(--main-shadow);

  &-mobile {
    color: var(--menu-color);
    border-radius: 24px;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.balance {
  text-align: center;
  align-self: center;
  display: flex;
  flex-direction: column;

  &-mobile {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
  }

  &-big {
    font-size: 32px;
    display: flex;
    gap: 8px;
  }
}

.address-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-mobile {
    display: flex;
    align-items: center;
    gap: 24px;
    @media (max-width: $screen-sm-max) {
      flex-wrap: wrap;
    }
  }

  .address {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}
.hide {
  display: none;
}
.open {
  max-height: 1000px;
  transition: max-height 300ms;
}

.action-buttons-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  &-mobile {
    display: flex;
    align-items: center;
    gap: 24px;
    @media (max-width: $screen-sm-max) {
      width: 100%;
    }
  }

  button {
    width: 36px;
  }
}

.wallet {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  .details {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 12px;
    cursor: pointer;
    .details {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
}
