// Utils

// Text style
.u-bold {
  font-family: "Poppins Bold", sans-serif;
}

.u-semibold {
  font-family: "Poppins SemiBold", sans-serif;
}

.u-light {
  font-family: "Poppins Light", sans-serif;
}

.u-extralight {
  font-family: "Poppins Extra Light", sans-serif;
}

.u-underline {
  text-decoration: underline;
  text-underline-offset: 2px;
}

// Font size

.u-text--h1 {
  font-size: $fontH1;
}

.u-text--h2 {
  font-size: $fontH2;
}

.u-text--h3 {
  font-size: $fontH3;
}

.u-text--h4 {
  font-size: $fontH4;
}

.u-text--large {
  font-size: $fontLarge;
}

.u-text--medium {
  font-size: $fontMedium;
}

.u-text--small {
  font-size: $fontSmall;
}

.u-text--xsmall {
  font-size: $fontXSmall;
}

// Font color
.u-text--primary {
  color: var(--text-primary);
}

.u-text--content {
  color: var(--content-color);
}

.u-text--error {
  color: var(--error);
}

// Text alignment
.u-text--center {
  text-align: center;
}

.u-text--right {
  text-align: right;
}

// Spacings
.m-0 {
  margin: 0;
}

// Cursor
.u-cursor--pointer {
  cursor: pointer;
}

.u-justify--end {
  display: flex;
  justify-content: flex-end;
}
