@import "assets/scss/variables";
@import "assets/scss/utils";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.toast-wrapper {
  bottom: 24px;
  right: 24px;
  position: absolute;
  z-index: 100;

  .toast-content {
    display: flex;
    align-items: flex-start;
    padding: 12px;
    gap: 10px;
    border-radius: 8px;
    background: var(--info-bg);
    color: var(--text-color);
    z-index: 100;
    pointer-events: none;
    animation: fadeIn 0.7s ease 0s normal 1;

    &.success {
      background: var(--success-bg);
    }

    &.warning {
      background: var(--warning-bg);
    }

    &.error {
      background: var(--error-bg);
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      font-size: 14px;

      .title {
        font-family: "Poppins Bold", sans-serif;
      }

      .msg {
        margin: 0;
      }
    }
  }
}

[data-theme="light"] {
  --text-color: #{$darkBlue100};
  --success-bg: #{$success10};
  --warning-bg: #{$warning10};
  --error-bg: #{$error20};
  --info-bg: #{$lightBlue60};
}

[data-theme="dark"] {
  --text-color: #{$lightBlue10};
  --success-bg: #{$success100};
  --warning-bg: #{$warning60};
  --error-bg: #{$error90};
  --info-bg: #{$darkBlue50};
}
