@import "assets/scss/variables";
@import "assets/scss/fonts";
@import "assets/scss/utils";
@import "assets/scss/general";

.user-container {
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;

  .user-box {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &:hover {
    cursor: pointer;
  }

  > div:first-child {
    flex-shrink: 0;
  }
}
