@import "assets/scss/variables";
@import "assets/scss/general";
@import "assets/scss/utils";

.diploma-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 60px;

  .card-pic {
    width: 100%;
    aspect-ratio: 1.5 / 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }

    &--empty {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--card-bg);
      border-radius: 8px;

      svg {
        fill: var(--card-svg);
        width: 20%;
        height: 20%;
      }
    }
  }

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }

  .flex-container {
    margin-top: 16px;
    display: flex;
    gap: 8px;
  }

  .details-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .detail-block-wrapper {
    display: flex;
    gap: 12px;
  }

  .detail-block {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    button {
      margin-top: 8px;
    }
  }

  .detail-title {
    color: var(--content-color);
    font-size: $fontXSmall;
  }

  .detail-desc {
    color: var(--text-primary);
    font-size: $fontSmall;
  }

  .qr-wrapper {
    display: flex;
    align-items: stretch;
    background: var(--card-bg);
    padding: 12px;
    gap: 12px;
    border-radius: 8px;
    flex: none;
    flex-grow: 0;

    .qr-svg {
      border-radius: 4px;
      background: #fff;
      padding: 4px;
    }

    .qr-details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .qr-btns-wrapper {
      display: flex;
      gap: 20px;
    }
  }

  @media (max-width: #{$screen-lg-max}) {
    flex-direction: column;
    gap: 24px;
  }

  @media (max-width: #{$screen-sm-max}) {
    .detail-block-wrapper {
      flex-direction: column;
    }
  }
}
