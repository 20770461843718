@import "assets/scss/variables";
@import "assets/scss/fonts";
@import "assets/scss/utils";

.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 105px;
  padding: 24px;
  background-color: var(--header-bg);
  backdrop-filter: blur(5px);
  z-index: 10;
  transition: background-color 350ms;

  &.no-auth {
    position: fixed;
  }

  @media (max-width: $screen-lg-max) {
    display: none;
  }

  .logo-and-nav-container {
    display: flex;
    align-items: center;
    gap: 106px;
    width: 100%;
    .logo {
      cursor: pointer;
    }
  }

  .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    width: 100%;

    .divider {
      height: 50px;
      width: 1px;
      background-color: var(--divider-bg);
      flex-shrink: 0;
    }
    button {
      align-self: center;
      flex-shrink: 0;
    }
  }

  @media print {
    display: none;
  }
}

.header-mobile-wrapper {
  position: relative;
  display: none;
  justify-content: space-between;
  flex-direction: column;
  background: var(--mobile-menu-bg);
  transition: background-color 350ms;
  z-index: 1;

  &.no-auth {
    position: fixed;
    top: 0;
    left: 0;
  }

  @media (max-width: $screen-lg-max) {
    display: flex;
    width: 100%;
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;

    .mobile-menu-wrapper {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 70px;
      left: 0;
      z-index: 9;
      width: 100%;
      background: var(--mobile-menu-bg);
      backdrop-filter: blur(5px);
      border-radius: 0 0 16px 16px;
      overflow: hidden;
      height: 0;
      transition: height 300ms;

      .mobile-menu-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 24px;

        .tabs-container {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
      }
    }
    .open {
      height: calc(100vh - 70px);
      overflow-y: scroll;
      transition: height 300ms;
    }
  }
  .breadcrumbs-container {
    display: inherit;
    padding: 0 24px;
  }
  @media print {
    display: none;
  }
}
.open-bg {
  background-color: var(--mobile-menu-bg);
  transition: background-color 0ms;
}

[data-theme="light"] {
  --mobile-menu-bg: rgba(255, 255, 255, 0.8);
}
[data-theme="dark"] {
  --mobile-menu-bg: rgba(22, 23, 37, 0.9);
}
