@import "assets/scss/variables";
@import "assets/scss/utils";

.tooltip-comp {
  cursor: pointer;
  margin: 0;
}
.tooltip-wrapper {
  color: var(--tooltip-color);
  background: var(--tooltip-bg);
  border-radius: 8px;
  font-size: $fontXSmall;
  font-family: "Poppins Regular", sans-serif;
  z-index: 1;

  @media (max-width: $screen-lg-max) {
    max-width: 70vw;
  }
}
.full-width {
  width: 100%;
}
