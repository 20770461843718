@import "assets/scss/variables";
@import "assets/scss/utils";

.deposit-popup {
  position: relative;
  display: flex;
  flex-direction: column;

  h5 {
    margin-bottom: 8px;
  }

  .wallet-container {
    display: flex;
    flex-direction: column;
    .title {
      display: flex;
      align-items: center;
      gap: 4px;
      margin-bottom: 8px;
    }
    .wallet {
      display: flex;
      padding: 4px 4px 4px 20px;
      justify-content: space-between;
      align-items: center;
      color: var(--address-color);
      background-color: var(--address-bg);
      border-radius: 16px;
      button {
        display: flex;
        align-items: center;
        border-radius: 12px;
        padding: 9.5px 16px;
        height: 40px;
      }
    }
  }
}

[data-theme="dark"] {
  --address-color: #{$greyishBlue50};
  --address-bg: #{$greyishBlue90};
}
[data-theme="light"] {
  --address-color: #{$greyishBlue50};
  --address-bg: #{$lightBlue30};
}
