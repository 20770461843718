@import "assets/scss/variables";
@import "assets/scss/utils";

.providers-container {
  display: flex;
  flex-direction: column;

  .provider-btn {
    display: flex;
    justify-content: start;
    div {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    border-radius: 16px;
    height: 64px;
  }
}
