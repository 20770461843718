@import "assets/scss/variables";
@import "assets/scss/utils";
@import "assets/scss/general";

.c-items-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  .c-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px;
    gap: 12px;
    margin-bottom: 8px;
    border-radius: 8px;
    color: var(--text-primary);
    font-family: "Poppins SemiBold", sans-serif;
    font-size: $fontSmall;

    .icon {
      width: 24px;
      height: 24px;
      svg {
        fill: var(--text-primary);
        width: 24px;
        height: 24px;
      }
    }
    .title {
      white-space: nowrap;
    }

    &:hover {
      background-color: var(--active-tab-bg-hover);
      cursor: pointer;
    }

    &--active {
      background-color: var(--active-tab-bg);
      transition: background-color 350ms;
    }
  }
}
.c-switch {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  color: var(--text-primary);
  font-family: "Poppins SemiBold", sans-serif;
  font-size: $fontSmall;
  cursor: pointer;

  .c-icon {
    width: 24px;
    height: 24px;
    svg {
      fill: var(--text-primary);
      width: 24px;
      height: 24px;
    }
  }
}
