@import "assets/scss/variables";
@import "assets/scss/fonts";
@import "assets/scss/utils";

.nav {
  display: flex;
  align-items: center;
  background-color: var(--nav-bg);
  color: var(--text-primary);
  gap: 4px;
  font-family: "Poppins SemiBold", sans-serif;
  font-size: $fontSmall;
  padding: 9.5px 16px;
  border-radius: 8px;

  .nav-link {
    display: flex;
    align-items: center;
    gap: 4px;
    .link {
      color: var(--arrow-bg);
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .arrow {
      transform: rotate(90deg);
      fill: var(--arrow-bg);
    }
    .last {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @media (max-width: $screen-xl-max) {
        max-width: 200px;
      }
    }
  }
}

[data-theme="light"] {
  --nav-bg: #{$lightBlue20};
  --arrow-bg: #{$greyishBlue20};
}

[data-theme="dark"] {
  --nav-bg: #{$greyishBlue90};
  --arrow-bg: #{$greyishBlue50};
}
