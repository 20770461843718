@import "assets/scss/variables";
@import "assets/scss/utils";

.popup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .icon {
    margin: 48px 0 24px;
    align-self: center;
  }

  .loader {
    margin: 40px 0 60px;
    align-self: center;
  }

  .title {
    margin: 0 0 8px;
  }
  .msg {
    margin-bottom: 24px;
    color: var(--content-color);
    text-align: center;
    font-size: $fontSmall;
    overflow-wrap: break-word;
  }
  .btn-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    button {
      align-self: center;
    }
  }
}
