@import "assets/scss/variables";

.c-avatar {
  flex-shrink: 0;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
  &--default {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--avatar-bg);

    svg {
      fill: var(--avatar-svg);
      width: 55%;
      height: 55%;
    }
  }

  &--xs {
    width: 40px;
    height: 40px;
  }

  &--sm {
    width: 48px;
    height: 48px;
  }

  &--md {
    width: 56px;
    height: 56px;
  }

  &--lg {
    width: 96px;
    height: 96px;
  }

  &--xl {
    width: 160px;
    height: 160px;

    svg {
      width: 40%;
      height: 40%;
    }
  }
}

[data-theme="light"] {
  --avatar-bg: #{$lightBlue50};
  --avatar-svg: #{$lightBlue80};
}

[data-theme="dark"] {
  --avatar-bg: #{$greyishBlue80};
  --avatar-svg: #{$greyishBlue60};
}
