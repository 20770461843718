@import "assets/scss/variables";
@import "assets/scss/fonts";
@import "assets/scss/utils";
@import "assets/scss/general";

.user-container-mobile {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  align-items: flex-start;

  .user-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
    .details {
      display: flex;
      align-items: center;
      gap: 12px;
      word-break: break-word;
    }
  }

  .mobile-menu {
    position: relative;
    width: 100%;
    z-index: 1;
    height: 100%;
    max-height: 0px;
    overflow-y: hidden;
    transition: max-height 300ms;

    .c-menu {
      color: var(--menu-color);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-top: 16px;
      width: 100%;

      &-item {
        display: flex;
        align-items: center;
        gap: 16px;
        font-family: "Poppins SemiBold", sans-serif;
        font-size: $fontSmall;
        border-radius: 8px;
        padding: 16px;
        transition: background-color 350ms;
        margin: 0;

        svg {
          height: 24px;
          width: 24px;
          fill: var(--text-primary);
        }

        &:hover {
          background: var(--menu-item-hover);
          cursor: pointer;
        }

        &--disabled {
          color: $greyishBlue60;

          &:hover {
            background: var(--menu-bg);
            cursor: auto;
          }
        }
      }
    }
  }

  .open {
    max-height: 1000px;
    transition: max-height 300ms;
  }

  &:hover {
    cursor: pointer;
  }

  > div:first-child {
    flex-shrink: 0;
  }
}
