@import "assets/scss/variables";
@import "assets/scss/fonts";
@import "assets/scss/utils";
@import "assets/scss/general";

.theme-switcher {
  position: absolute;
  bottom: 24px;
  left: 24px;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: var(--bg);
  background-image: var(--bg-img);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: background-color 350ms;

  .wrapper-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 48px;
    width: 100%;
    height: 100%;
    padding: 105px 24px 24px;

    .text-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 8px;
      text-align: center;
      h1 {
        color: var(--text-primary);
        font-family: "Poppins Bold";
        font-weight: $weightSemiBold;
        margin: 0;
        @media (max-width: $screen-sm-max) {
          font-size: $fontH3;
        }
      }
      p {
        margin: 0;
      }
    }
    .socials-container {
      display: flex;
      align-items: center;
      gap: 16px;
      font-size: $fontSmall;

      .icons-container {
        display: flex;
        align-items: center;
        gap: 12px;
        a {
          display: flex;
          align-items: center;
          margin: 0;
        }
        svg {
          fill: var(--text-primary);
        }
      }
    }
  }
}
// TODO: copied from 404, move to general scss if needed again
[data-theme="light"] {
  --bg: #{$lightBlue30};
  --bg-img: url("../../assets/images/404-page/shapes-light.png");
}
[data-theme="dark"] {
  --bg: #{$darkBlue100};
  --bg-img: url("../../assets/images/404-page/shapes-dark.png");
}
